<template>
  <div class="find">
    <h1>{{ this.$t("auth.header_find") }}</h1>
    <div class="section">
      <label for="name">{{ $t("auth.title_name") }}</label>
      <input id="name" type="text" v-model="find_name" />
      <p class="error">{{ error_name }}</p>
    </div>
    <div class="section">
      <label for="email">{{ $t("auth.title_email") }}</label>
      <input
        id="email"
        type="email"
        placeholder="example@email.com"
        v-model="find_email"
      />
      <p class="error">{{ error_email }}</p>
    </div>
    <div class="section">
      <ul class="desc">
        <li>{{ $t("auth.label_find_password_desc1") }}</li>
        <li>{{ $t("auth.label_find_password_desc2") }}</li>
        <li>{{ $t("auth.label_find_password_desc3") }}</li>
      </ul>
    </div>
    <div class="section">
      <button
        class="button red"
        v-bind:class="{ inactive: !is_active_button }"
        @click="this.try_email_find"
      >
        {{ $t("auth.button_find") }}
      </button>
    </div>
    <div class="section text-center">
      <router-link to="/auth/login">
        {{ $t("auth.link_go_to_login") }}
      </router-link>
    </div>
  </div>

  <Spinner v-show="processing" />
</template>

<script>
export default {
  name: "Find",
  metaInfo() {
    return {
      title: this.$t("meta.title") + " :: " + this.$t("meta.find"),
      og: {
        title: this.$t("meta.title") + " :: " + this.$t("meta.find"),
      },
    };
  },
  data: function () {
    return {
      process_queue: [],

      find_name: "",
      find_email: "",
      is_active_button: false,
      error_name: "",
      error_email: "",
    };
  },
  computed: {
    processing() {
      if (this.process_queue.length <= 0) return false;
      else return true;
    },
  },
  watch: {
    find_name: function () {
      this.validate_name();
      this.check_button();
    },
    find_email: function () {
      this.validate_email();
      this.check_button();
    },
  },
  mounted() {
    // 이미 로그인 중이면...
    if (this.storage.is_logged_in()) {
      let self = this;

      this.$swal
        .fire({
          text: self.$t("auth.alert_already_logged_in"),
          icon: "error",
        })
        .then(() => {
          self.$router.push("/");
        });
    }
  },
  methods: {
    validate_email: function () {
      if (this.common.validate_email(this.find_email)) {
        this.error_email = "";
      } else {
        // 잘못된 이메일 형식입니다.
        this.error_email = this.$t("auth.error_invalid_email");
      }
    },

    validate_name: function () {
      if (this.find_name.trim() == "")
        // 이름을 입력해주세요.
        this.error_name = this.$t("auth.error_enter_name");
      else this.error_name = "";
    },

    check_button: function () {
      if (
        this.common.validate_email(this.find_email) &&
        this.find_name.trim() != ""
      ) {
        this.is_active_button = true;
      } else {
        this.is_active_button = false;
      }
    }, //-- check_button

    try_email_find: function () {
      if (this.processing || !this.is_active_button) return;
      let self = this;

      this.$swal
        .fire({
          text: this.$t("auth.confirm_email_find"),
          icon: "question",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            self.email_find();
          }
        }); //-- swal
    }, //-- try_email_find

    email_find: function () {
      if (this.processing || !this.is_active_button) return;

      let self = this;
      self.process_queue.push(1);

      this.axios
        .post(
          process.env.VUE_APP_API_URL + "/auth/password/find",
          {
            user_name: self.find_name,
            user_email: self.find_email,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          if (response.data.result == "ERR_SNS_JOIN") {
            self.$swal.fire({
              text: self.$t("auth.error_find_sns_joined"),
              icon: "error",
            });
            return;
          } else if (response.data.result != "OK") {
            self.$swal.fire({
              text: self.$t("common.error_occur"),
              icon: "error",
            });
            return;
          }

          self.$swal
            .fire({
              text: self.$t("auth.email_find_ok"),
              icon: "success",
            })
            .then(() => {
              self.$router.push("/auth/login");
            }); //-- swal
        })
        .catch(function (error) {
          alert(error);
        })
        .finally(function () {
          self.process_queue.pop();
        });
    },
  },
};
</script>

<style scoped>
.section .desc {
  list-style-type: "·";
  font-size: 0.875rem;
  color: #777;
  padding: 0 0 0 12px;
}
.section .desc > li {
  padding: 0 0 0 8px;
}
</style>
